<template>
  <b-container class="pt-10">
    <b-row>
      <b-col class="mt-10 d-flex justify-content-center">
        <span class="spinner-border spinner-border-lg"></span>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { logout } from '../services/AwsAmplifyService'
export default {
  name: 'Logout',
  mounted() {
    this.removeSessionData()
    this.goToLoggedOut()
  },
  methods: {
    removeSessionData() {
      logout()
      this.$store.commit('setLoggedInUser', null)
      this.$store.commit('setHasToken', false)
      window.localStorage.removeItem('orgData')
    },
    goToLoggedOut() {
      this.$router.push('/loggedout')
    }
  }
}
</script>
